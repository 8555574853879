// import React from "react"
// import { ApplyProvider } from "./src/context/menuContext"
import React from "react"
import { noShow_udesk_country, show_udesk_id1916_country } from './src/utils/regions.js'
import { RegionProvider } from "./src/context/regionContext"
import { LangProvider } from "./src/context/langContext"
import "antd/lib/message/style/index.less" // antd 提示样式，policy和问卷有用
// import "antd/dist/reset.css"
import "./src/styles/global.css"
import "./src/styles/custom.css"
// import "noto-sans-sc/all.css"
import "noto-sans-jp/all.css"
import "noto-sans-kr/styles.css"
import getSensors_url from './src/utils/sensors.js'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// const isBrowser = typeof window !== "undefined" && window

export const wrapRootElement = ({ element }) => {
  return (
    <RegionProvider>
      <LangProvider>
        {element}
      </LangProvider>
    </RegionProvider>
  )
}


export const onRouteUpdate = async ({ location }) => {

  // Remove the existing UDesk API script (if it's already added)
  const existingScript = document.getElementById("udesk-script")
  if (existingScript) {
    existingScript.remove()
  }
  // Extract the language from the URL (you need to adjust this depending on your URL structure)

  let id = ''
  const country = location.pathname.split('/')[1];
  if (noShow_udesk_country.includes(country)) {
    id = ''
  } else if (show_udesk_id1916_country.includes(country)) {
    id = 1916
  } else {
    id = 1913
  }

  // Add the script to the head component with the dynamic value

  if (!!id) {
    const script = `
    (function(a,h,c,b,f,g){a["UdeskApiObject"]=f;a[f]=a[f]||function(){(a[f].d=a[f].d||[]).push(arguments)};g=h.createElement(c);g.async=1;g.charset="utf-8";g.src=b;c=h.getElementsByTagName(c)[0];c.parentNode.insertBefore(g,c)})(window,document,"script","https://assets-cli.s5.udesk.cn/im_client/js/udeskApi.js","ud");
    ud({
        "code": "7fh4d4",
        "link": "https://xreal.s5.udesk.cn/im_client/?web_plugin_id=${id}",
        "language": "en-us"
    });`

    // Add the new UDesk API script with the updated language
    const newScript = document.createElement("script")
    newScript.id = "udesk-script"
    newScript.async = true
    newScript.charset = "utf-8"
    newScript.innerHTML = script
    document.head.appendChild(newScript)
  }



  // add 神策
  const sensors_url = await getSensors_url()
  const existingScript_sensors = document.getElementById("sensors-script")
  if (existingScript_sensors) {
    existingScript_sensors.remove()
  }
  let server_url = sensors_url
  // 亚洲 [`jp`,`kr`,`th` ]
  // 欧洲 [`de`, `de-en`,`fr`, `fr-en`, `it`, `it-en`,`uk`]
  // 北美 
  // if ([`jp`,`kr`,`th` ].includes(country)) {
  //   server_url=process.env.server_url_as
  // } else if ([`de`, `de-en`,`fr`, `fr-en`, `it`, `it-en`,`uk`].includes(country)) {
  //   server_url=process.env.server_url
  // } else {
  //   server_url=process.env.server_url_am
  // }

  const script_sensors = `if (typeof window['sensorsDataAnalytic201505'] !== 'undefined') {var sensors = window['sensorsDataAnalytic201505']; 
  sensors.use('PageLeave');
  sensors.init({server_url: "${server_url}",
  is_track_single_page:false,
  is_track_device_id:true,
  use_client_time:true,
  send_type:'ajax',
  batch_send:false,
  content_type:"application/json",
  show_log:${process.env.GATSBY_MODE=='production'?false:true},
  heatmap: {clickmap:'not_collect',scroll_notice_map:'not_collect'}});
  sensors.use(window.SensorsDataWebJSSDKPlugin.SessionEvent);
  sensors.quick('autoTrack')}`
  // Add the new UDesk API script with the updated language
  const newScript_sensors = document.createElement("script")
  newScript_sensors.id = "sensors-script"
  newScript_sensors.async = true
  newScript_sensors.charset = "utf-8"
  newScript_sensors.innerHTML = script_sensors
  document.head.appendChild(newScript_sensors)



}

