import getCountry from './getCountry.js'

const langList = {
    'base': "en",
    'us': "en",
    'cn': 'zh',
    'jp': 'ja',
    'kr': 'ko',
    'th': "th",
    'uk': "en",
    'de': "de",
    'de-en': "en",
    'fr': "fr",
    'fr-en': "en",
    'it': "it",
    'it-en': "en",
    'cz-en':'en',
    // 'cz':'cz',
    'nl-en':'en',
    // 'nl':"nl"
    'es-en':"en",
}
// cn除外所有的
const regionNoCn = [`us`,`jp`, `kr`, `th`, `uk`, `de`, `de-en`, `fr`, `fr-en`, `it`, `it-en`,'nl-en','cz-en','es-en'];
// 全部
const regionAll = [`cn`, ...regionNoCn];

//footer显示
const FooterData = {
   
    'Europe': [
        {
            region: "uk",
            regionText: "United Kindom",
            lang: "en",
            langText: "English",
        },
        {
            region: "de",
            regionText: "Deutschland",
            lang: "de",
            langText: "Deutsch",
        },
        {
            region: "de-en",
            regionText: "Germany",
            lang: "en",
            langText: "English",
        },
        {
            region: "fr",
            regionText: "France",
            lang: "fr",
            langText: "Français",
        },
        {
            region: "fr-en",
            regionText: "France",
            lang: "fr",
            langText: "English",
        },
        {
            region: "it",
            regionText: "Italia",
            lang: "it",
            langText: "Italiano",
        },
        {
            region: "it-en",
            regionText: "Italy",
            lang: "it",
            langText: "English",
        },
        {
            region: "cz-en",
            regionText: "Czechia",
            lang: "cz",
            langText: "English",
        },
        {
            region: "nl-en",
            regionText: "Netherlands",
            lang: "nl",
            langText: "English",
        },
         {
            region: "es-en",
            regionText: "Spain",
            lang: "es",
            langText: "English",
        },
        // {
        //     region: "nl",
        //     regionText: "Nederland",
        //     lang: "nl",
        //     langText: "Nederlands",
        // },
        // {
        //     region: "nl-en",
        //     regionText: "Nederland",
        //     lang: "en",
        //     langText: "English",
        // }
    ],
    'Asia': [
        {
            region: "cn",
            regionText: "中国",
            lang: "zh",
            langText: "简体中文",
        },
        {
            region: "jp",
            regionText: "日本",
            lang: "ja",
            langText: "日本語",
        },
        {
            region: "kr",
            regionText: "대한민국",
            lang: "ko",
            langText: "한국어",
        },
        {
            region: "th",
            regionText: "ประเทศไทย",
            lang: "th",
            langText: "ภาษาไทย",
        }
    ],
    'North America': [
        {
            region: "us",
            regionText: "United States",
            lang: "en",
            langText: "English",
        }
    ],
    'Global':[
        {
            region:"base",
            regionText: "Global",
            lang: "en",
            langText: "English",
        }
    ]
}

//兼容性列表
const compList={
    'base':"US",
    'us':"US",
    'jp':"JP",
    'kr':"KR",
    'th':"TH",
    'de':"DE",
    'de-en':"DE",
    'uk':"GB",
    'fr':"FR",
    'fr-en':"FR",
    'it':"IT",
    'it-en':"IT",
    'nl-en':"NL",
    'cz-en':"CZ",
    'es-en':"ES",
}
const getCompatibilityCode=()=>{
    const region = getCountry()
    return compList[region]
}
const getCompatibilityCode_light=()=>{
    const region = getCountry()
    return compList[region]
}



//客服按钮相关国家
const noShow_udesk_country=['cn', `jp`, `kr`, `th`, `de`, `de-en`, `fr`, `fr-en`, `it`, `it-en`, `nl`, `nl-en`,'cz-en','es-en'] //不显示客服按钮的国家
const show_udesk_id1916_country=[`uk`] //显示客服按钮id为1916的国家

const ota_region_go_en=['base','us','cz-en','nl-en','es-en'] //ota跳转到默认英文的国家

//接口ip对应国家
const ipCode={
    "US":"us",
    "JP":"jp",
    "KR":"kr",
    "TH":"th",
    "CN":"cn",
    "GB":"uk",
    "DE":"de",
    "FR":"fr",
    "IT":"it",
    "NL":"nl-en",
    "CZ":"cz-en",
    "ES":"es-en",
}

const no_sale_air_country=['jp','kr','th']

const footer_email_list={
    'base':"https://manage.kmail-lists.com/subscriptions/subscribe?a=UacNcq&g=WMd2dW",
    'us':"https://manage.kmail-lists.com/subscriptions/subscribe?a=UacNcq&g=WMd2dW",
    'jp':"https://manage.kmail-lists.com/subscriptions/subscribe?a=VsLX99&g=WVD2QV",
    'kr':"https://manage.kmail-lists.com/subscriptions/subscribe?a=UacNcq&g=WMd2dW",
    'th':"https://manage.kmail-lists.com/subscriptions/subscribe?a=UacNcq&g=WMd2dW",
    'de':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=Yd6Sck",
    'de-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=Yd6Sck",
    'uk':"https://manage.kmail-lists.com/subscriptions/subscribe?a=R5SSQd&g=UqXAk5",
    'fr':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=Yd6Sck",
    'fr-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=Yd6Sck",
    'it':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=Yd6Sck",
    'it-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=Yd6Sck",
    'nl-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=Yd6Sck",
    'cz-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=Yd6Sck",
    'es-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=Yd6Sck",
}

const gf_teaser_email_list={
    "base":'https://manage.kmail-lists.com/subscriptions/subscribe?a=UacNcq&g=X9dfiC',
    "us":'https://manage.kmail-lists.com/subscriptions/subscribe?a=UacNcq&g=X9dfiC',
    "uk":"https://manage.kmail-lists.com/subscriptions/subscribe?a=R5SSQd&g=Y5UPf2",
    "de":"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=WTUJiN",
    'de-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=WTUJiN",
    'fr':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=WTUJiN",
    'fr-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=WTUJiN",
    'it':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=WTUJiN",
    'it-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=WTUJiN",
    'nl-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=WTUJiN",
    'cz-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=WTUJiN",
    'es-en':"https://manage.kmail-lists.com/subscriptions/subscribe?a=SYB2PG&g=WTUJiN",

}


const no_gf_teaser_country = ['jp','kr','th']

export {
    no_gf_teaser_country,
    ota_region_go_en,
    noShow_udesk_country,
    show_udesk_id1916_country,
   
    langList,
    regionNoCn,
    regionAll,
    FooterData,
    getCompatibilityCode,
    getCompatibilityCode_light,
    ipCode,
    no_sale_air_country,
    footer_email_list,
    gf_teaser_email_list
}